<template>
  <div
    class="fixed z-10 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <div
        class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
      >
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="sm:flex sm:items-start">
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <div>
                <h3
                  class="text-lg leading-6 font-medium text-gray-900"
                  id="modal-title"
                >
                  Cena vaší nemovitosti
                </h3>
              </div>

              <div class="pt-2">
                <div class="flex">
                  <Info />
                  <span
                    style="line-height: 24px"
                    class="text-gray-500 text-md text-body-1 select-none pl-2"
                    >Nutno potvrdit pro zobrazení v inzerátu</span
                  >
                </div>
              </div>
              <div class="mt-2">
                <input
                  v-mask="'###########'"
                  name="userPrice"
                  @input="cantIncludes(userPrice)"
                  v-model="userPrice"
                  type="number"
                  class="focus:outline-none shadow appearance-none border rounded py-2 px-3 text-grey-darker mr-8"
                />
                Kč
              </div>
              <div
                v-if="submitted && !$v.userPrice.required"
                class="font-medium text-sm text-red-500"
              >
                {{ $t("ErrorMessages.FieldIsRequired") }}
              </div>
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button
            @click="updatePrice()"
            type="button"
            class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
          >
            Potvrdit
          </button>
          <button
            @click="[closeModalPrice(), $emit('no-confirm-valuo-price')]"
            type="button"
            class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          >
            Zavřít
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { changePrice } from "../../services/rest/services";
import { mapState } from "vuex";
import Info from "../../components/svg/Info.vue";

export default {
  name: "PriceModal",
  components: {
    Info,
  },
  props: {
    price: Number,
    valuoPriceClicked: Boolean,
  },
  computed: {
    ...mapState("main", ["currentProperty"]),
  },
  data() {
    return {
      submitted: false,
      userPrice: null,
    };
  },
  async mounted() {
    if (this.price) {
      this.userPrice = this.price;
    }
    if (this.currentProperty.propertyPrice) {
      this.userPrice = parseInt(this.currentProperty.propertyPrice);
    }
    if (this.valuoPriceClicked) {
      this.userPrice = this.price;
    }
  },
  methods: {
    closeModalPrice() {
      this.$emit("closeModalPrice", false);
    },
    async updatePrice() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      //this.$emit("updatePrice", this.userPrice);
      await changePrice(
        JSON.stringify({
          propertyId: this.currentProperty.id,
          newPrice: this.userPrice,
        })
      ).then(async (status) => {
        return this.$emit("updatePrice", status);
      });
    },
    cantIncludes(userPrice) {
      this.userPrice = userPrice;
      this.userPrice = this.userPrice ? this.userPrice : window.event;
      let charCode = this.userPrice.which
        ? this.userPrice.which
        : this.userPrice.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        this.userPrice = null;
      } else {
        return true;
      }
    },
  },
  validations: {
    userPrice: { required },
  },
};
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>
