<template>
  <div
    class="fixed z-10 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <div
        class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
      >
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="sm:flex sm:items-start">
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3
                class="text-md leading-6 font-medium text-gray-900 pr-8"
                id="modal-title"
              >
                <p>
                  Cenu nemovitosti jste již změnili. Pokud chcete cenu změnit
                  znovu, proveďte tak při dalším přihlášení.
                </p>
              </h3>
              <div
                v-if="submitted && !$v.userPrice.required"
                class="font-medium text-sm text-red-500"
              >
                {{ $t("ErrorMessages.FieldIsRequired") }}
              </div>
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button
            @click="logout()"
            type="button"
            class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
          >
            Odhlásit se
          </button>
          <button
            @click="closeModalError()"
            type="button"
            class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          >
            Zavřít
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { LOGOUT } from "@/store/user/constants";

export default {
  name: "PriceModal",
  data() {
    return {
      submitted: false,
      userPrice: null,
    };
  },
  methods: {
    ...mapActions("user", {
      logoutStore: LOGOUT,
    }),

    closeModalError() {
      this.$emit("closeModalError", true);
    },

    async logout() {
      try {
        await this.logoutStore();
        // if the page is not private, you can stay on router name 'preview-page' after logout
        if (this.$router.history.current.name === "preview-page") {
          return;
        } else
          return this.$router.replace(
            { path: "/", params: { ...this.$route.params } },
            () => {
              this.$router.go(0);
            }
          );
      } catch (error) {
        this.$toast("Chyba při odhlášení", {
          className: ["et-alert"],
        });
      }
    },
  },
};
</script>

<style></style>
