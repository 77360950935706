<template>
  <div>
    <section>
      <div class="max-w-4xl mx-auto mt-6">
        <div class="text-center mb-12 text-2xl text-body-1 font-semibold">
          Strategie a dokumentace
        </div>
        <div
          :class="
            valuoData ? 'bg-green-200 bg-opacity-25' : 'bg-body-1 bg-opacity-5'
          "
          class="mx-8 p-6 rounded-md border-blue-700"
        >
          <div class="grid grid-cols-1 md:grid-cols-2">
            <div class>
              <h2 class="text-17 text-body-4 font-bold">
                Profesionální odhad nemovitosti
              </h2>
            </div>
            <div class="text-left md:text-right">
              <h2 class="text-17 text-body-4 font-bold">
                <p v-if="!priceMapComparator">Zdarma</p>
              </h2>
            </div>
          </div>
          <div class="mt-6">
            <p class="text-17 text-body-4">
              Výstupem této služby je cenová mapa, která vám vypíše minimální,
              průměrnou a nejvyšší cenu za vaši nemovitost. Odhad ceny vychází z
              charakteristiky vaší nemovitosti a pomůže vám cenu za prodej domu
              či bytu “nepřepálit” ani nepodcenit.
            </p>
            <p class="text-17 text-body-4 pt-2">
              Cenová mapa se po objednání a zakoupení služby objeví na této
              kartě. Poté máte na výběr, zda některou z nabízených částek
              přijmete, nebo navrhnete svou vlastní.
            </p>
          </div>
          <div class="mt-6">
            <div v-if="allDataFetched">
              <div
                class="grid grid-flow-row auto-cols-max"
                v-if="!priceMapComparator"
              >
                <!-- Free for while -->
                <!-- <div>
                  <ButtonSpinnerStripe :stripe-price-id="priceMap.stripePriceId" />
                </div> -->
                <div>
                  <!-- Valuo free for while -->
                  <button
                    v-if="freeValuoDataShowButton"
                    class="modal-open px-4 bg-white h-12 text-body-1 rounded-md focus:outline-none border-2 border-body-1 mt-8 md:mt-0"
                    @click="getValuoForFree"
                  >
                    <img
                      src="/images/tag 1.png"
                      class="inline-flex mr-4"
                    />Získat odhad ceny nemovitosti zdarma
                  </button>
                  <!-- Valuo free for while -->
                  <button
                    :class="freeValuoDataShowButton ? 'ml-8' : ''"
                    class="modal-open px-4 bg-white h-12 text-body-1 rounded-md focus:outline-none border-2 border-body-1 mt-8 md:mt-0"
                    @click="
                      [
                        (valuoPriceClicked = false),
                        (addPriceModalOpen = !addPriceModalOpen),
                      ]
                    "
                  >
                    <img
                      src="/images/tag 1.png"
                      class="inline-flex mr-4"
                    />Zadat vlastní cenu
                  </button>
                </div>
                <div class="flex flex-row">
                  <div
                    v-if="currentProperty.propertyPrice !== null"
                    class="pt-3 text-white rounded-md font-bold focus:outline-none text-body-1"
                  >
                    Nastavená cena nemovitosti:
                    {{
                      this.propertyPrice === null
                        ? "Neuvedeno"
                        : Intl.NumberFormat("cs-CS", {
                            style: "currency",
                            currency: "CZK",
                          }).format(this.propertyPrice)
                    }}
                  </div>
                </div>
              </div>

              <div
                class="grid grid-flow-col auto-cols-max"
                v-if="priceMapComparator"
              >
                <button
                  class="modal-open px-4 bg-white h-12 text-body-1 rounded-md focus:outline-none border-2 border-body-1 mt-8 md:mt-0"
                  @click="addPriceModalOpen = !addPriceModalOpen"
                >
                  <img src="/images/tag 1.png" class="inline-flex mr-4" />Zadat
                  vlastní cenu
                </button>
                <div
                  v-if="propertyPrice"
                  class="ml-8 pt-3 text-white rounded-md font-bold focus:outline-none text-body-1"
                >
                  Vlastní cena:
                  {{
                    this.propertyPrice === null
                      ? null
                      : Intl.NumberFormat("cs-CS", {
                          style: "currency",
                          currency: "CZK",
                        }).format(this.propertyPrice)
                  }}
                </div>
              </div>
            </div>
            <div class="pt-2 pb-2.5" v-if="!allDataFetched">
              <img src="../../../public/images/dataLoading.gif" />
            </div>
          </div>
          <div class="overflow" v-if="openValuoJson">
            <div
              class="grid grid-rows-3 md:grid-rows-1 lg:grid-rows-1 xl:grid-rows-1 2xl:grid-rows-1 grid-flow-col gap-2 mt-6"
            >
              <div
                @click="
                  updateValuoPrice(
                    valuoData.result.min_price_m2 * valuoData.result.calc_area
                  )
                "
                :class="valuoCss"
              >
                <div style="font-weight: 700">Minimální cena</div>
                <div style="font-weight: 500">
                  Cena za m² {{ serialize(valuoData.result.min_price_m2) }}
                </div>
                <div style="font-weight: 500">
                  Celková cena
                  {{
                    serialize(
                      valuoData.result.min_price_m2 * valuoData.result.calc_area
                    )
                  }}
                </div>
              </div>

              <div
                @click="
                  updateValuoPrice(
                    valuoData.result.avg_price_m2 * valuoData.result.calc_area
                  )
                "
                :class="valuoCss"
              >
                <div style="font-weight: 700">Průměrná cena</div>
                <div style="font-weight: 500">
                  Cena za m² {{ serialize(valuoData.result.avg_price_m2) }}
                </div>
                <div style="font-weight: 500">
                  Celková cena
                  {{
                    serialize(
                      valuoData.result.avg_price_m2 * valuoData.result.calc_area
                    )
                  }}
                </div>
              </div>

              <div
                @click="
                  updateValuoPrice(
                    valuoData.result.max_price_m2 * valuoData.result.calc_area
                  )
                "
                :class="valuoCss"
              >
                <div style="font-weight: 700">Maximální cena</div>
                <div style="font-weight: 500">
                  Cena za m² {{ serialize(valuoData.result.max_price_m2) }}
                </div>
                <div style="font-weight: 500">
                  Celková cena
                  {{
                    serialize(
                      valuoData.result.max_price_m2 * valuoData.result.calc_area
                    )
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="
            cadastralDataInDatabase
              ? 'bg-green-200 bg-opacity-25'
              : 'bg-body-1 bg-opacity-5'
          "
          class="mx-8 p-6 rounded-md border-blue-700 mt-6"
        >
          <div class="grid grid-cols-1 md:grid-cols-2">
            <div class>
              <h2 class="text-17 text-body-4 font-bold">List vlastnictví</h2>
            </div>
            <div class="text-left md:text-right">
              <h2 class="text-17 text-body-4 font-bold">
                <p
                  class="text-body-1 text-xl"
                  v-if="titleDeedComparator || cadastralDataInDatabase"
                >
                  ✔
                </p>
                <p v-if="!titleDeedComparator && !cadastralDataInDatabase">
                  100 Kč + 50 Kč/strana
                </p>
              </h2>
            </div>
          </div>
          <div class="mt-6">
            <p class="text-17 text-body-4">
              List vlastnictví je důležitý dokument, který deklaruje, že jste
              skutečným majitelem nemovitosti. Zvyšuje tedy vaši důvěryhodnost
              vůči zájemci o koupi.
              <router-link
                class="font-bold text-body-1 hover:opacity-80 duration-300 focus:outline-none"
                to="/list-vlastnictvi-informace"
                target="_blank"
                >Co je list vlastnictví?</router-link
              >
            </p>
          </div>
          <div class="mt-6 justify-items-center">
            <div
              class="text-red-600 bg-red-50 rounded-md p-4 border-2 border-red-600"
              v-if="warningCase3"
            >
              <div class="font-bold">
                Váš list vlastnictví obsahuje více vlastníků!
              </div>
              <div>
                Při prodeji nemovitosti nemusí být celý dokument zohledněn, a
                proto Vám doporučujeme shlédnout sekci "Služby" v
                <button
                  class="hover:font-bold"
                  @click="
                    $router.push({
                      name: 'pravni-sluzby',
                      params: { direct: true },
                    })
                  "
                >
                  <div class="font-bold">Právních službách</div></button
                >.
              </div>
            </div>
            <div v-if="allDataFetched">
              <div>
                <div class="grid grid-flow-col auto-cols-max">
                  <div
                    v-if="
                      (compositionCount < 2 && currentProperty.propertyType === 1) || (compositionCount < 1 && currentProperty.propertyType === 2)
                    "
                    class="grid grid-flow-col auto-cols-max"
                  >
                    <ButtonSpinnerStripe
                      :stripe-price-id="proposalPropertyDeposit.stripePriceId"
                    />
                  </div>
                  <!-- Tlačítko pro zaslání na e-mail -->
                  <div v-if="titleDeedComparator">
                    <div v-if="!this.cadastralSendedToEmail">
                      <button
                        @click="titleDeedChoose()"
                        class="px-4 bg-white h-12 text-body-1 hover:bg-blue-100 rounded-md focus:outline-none border-2 border-body-1 mt-8 md:mt-0"
                      >
                        {{ generateToEmail }}
                      </button>
                    </div>
                  </div>
                  <!-- Tlačítko pro zobrazení LV, pokud ho uživatel má -->
                  <!-- TODO: Button for pdf view. Commented after discussion. Possible future use. -->
                  <!-- <div v-if="this.isPdfLv && this.isPdfLv.id">
                    <div>
                      <button
                        @click="getLvWithNewTab"
                        class="px-4 bg-white h-12 text-body-1 hover:bg-blue-100 rounded-md focus:outline-none border-2 border-body-1 mt-8 md:mt-0"
                      >
                        Váš list vlastnictví
                      </button>
                    </div>
                  </div> -->
                </div>

                <div class="pt-4" v-if="openUploadManager">
                  <input
                    @change="uploadUserImagesHandler()"
                    style="color: blue"
                    type="file"
                    ref="myFiles"
                  />
                  <p style="color: blue" v-if="canContinueToUpload">
                    Soubor nahrán, vyberte další ...
                  </p>
                </div>

                <div v-for="file in titleDeed.files" :key="file.name">
                  <span class="py-4 text-17 text-body-4 font-bold pl-4">
                    {{ file.name }}
                  </span>
                  <span class="py-4 text-17 text-body-1 font-bold">
                    <img
                      src="/images/trash-2opx 1.png"
                      class="inline ml-4 pb-1"
                    />
                  </span>
                </div>
              </div>
            </div>
            <div class="pt-2 pb-2.5" v-if="!allDataFetched">
              <img src="../../../public/images/dataLoading.gif" />
            </div>
          </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 mt-12 mx-8 mb-24">
          <div class="hover:opacity-50 duration-300">
            <button
              class="px-8 text-body-3 border border-body-3 font-bold text-15 focus:outline-none rounded-md h-14"
              @click="$router.go(-1)"
            >
              Krok zpět
            </button>
          </div>
          <div
            class="text-left md:text-right hover:opacity-80 mt-8 md:mt-0 duration-300"
          >
            <button
              class="px-8 font-bold text-15 bg-body-3 text-white focus:outline-none rounded-md h-14"
              @click="save()"
            >
              Pokračovat
            </button>
          </div>
        </div>
      </div>
    </section>

    <div>
      <PriceModal
        v-if="addPriceModalOpen"
        v-on:no-confirm-valuo-price="valuoPriceClicked = false"
        :valuoPriceClicked="valuoPriceClicked"
        v-on:closeModalPrice="closeModalPrice($event)"
        v-on:updatePrice="updatePriceMap($event)"
        :price="price"
      />
    </div>

    <div>
      <ErrorModal
        v-if="openErrorModal"
        v-on:closeModalError="closeModalError($event)"
      />
    </div>

    <div>
      <OwnershipUploadModal
        v-if="addOwnershipModalOpen"
        :titleDeed="titleDeed"
        v-on:closeModalOwnership="closeModalOwnership($event)"
        v-on:deleteUserPrice="deleteUserPrice()"
        v-on:updateOwnership="updateOwnership($event)"
      />
    </div>
    <div v-if="cadastralExecuted">
      <div
        class="fixed z-10 inset-0 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
        >
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>
          <span
            class="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
            >&#8203;</span
          >
          <div
            class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          >
            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div>
                <div class="text-center sm:mt-0 sm:ml-4 sm:text-left pb-2">
                  <h3
                    class="text-xl leading-6 font-bold text-body-1 select-none py-4 text-center"
                    id="modal-title"
                  >
                    Generuji list vlastnictví do E-mailu
                  </h3>
                  <div class="flex justify-center">
                    <div></div>
                    <div v-if="!cadastralSucceed && !cadastralError">
                      <img src="../../../public/images/dataLoading.gif" />
                    </div>
                    <div></div>
                  </div>
                  <div v-if="cadastralSucceed" class="flex justify-center">
                    <div></div>
                    <div
                      v-if="!cadastralError"
                      class="text-lg text-body-1 pt-2"
                    >
                      List vlastnictví byl úspěšně vygenerován!
                    </div>
                    <div></div>
                  </div>
                  <div
                    v-if="cadastralBuilding.documentCase === 3"
                    class="flex justify-center"
                  >
                    <div></div>
                    <div class="text-lg text-red-800 pt-2">
                      Pozor! List vlastnictví obsahuje více, než dvě osoby!
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="changePriceErrorModal">
      <div
        class="fixed z-10 inset-0 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
        >
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>
          <span
            class="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
            >&#8203;</span
          >
          <div
            class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          >
            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div>
                <div class="text-center sm:mt-0 sm:ml-4 sm:text-left pb-2">
                  <h3
                    class="text-md leading-6 font-bold text-red-700 select-none py-4 text-center"
                    id="modal-title"
                  >
                    Cenu se nepodařilo upravit. Zkuste to prosím za chvíli.
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import PriceModal from "@/components/strategy/PriceModal";
import ErrorModal from "@/components/strategy/ErrorModal";
import OwnershipUploadModal from "@/components/strategy/OwnershipUploadModal";
import ButtonSpinnerStripe from "@/components/buttons/ButtonSpinnerStripe";
import {
  getCadastralBuilding,
  getCadastralData,
  getCompositionList,
  getCadastralList,
  getStripe,
} from "../../services/rest/services";
import axios from "axios";
import { IS_BETA, IS_LOCALHOST } from '../../globalConstants';

export default {
  components: {
    PriceModal,
    ErrorModal,
    OwnershipUploadModal,
    ButtonSpinnerStripe,
  },
  computed: {
    ...mapState("main", ["currentProperty"]),
    ...mapState("user", ["user"]),
    propertyPrice() {
      return parseInt(this.currentProperty.propertyPrice);
    },
  },
  data() {
    return {
      valuoPriceClicked: false,
      freeValuoDataShowButton: false,
      warningCase3: false,
      changePriceErrorModal: false,
      price: null,
      lvGenerated: false,
      valuoCss:
        "text-left p-2 border-2 rounded-md hover:border-body-1 cursor-pointer border-gray-200 bg-white hover:bg-body-1 hover:bg-opacity-5",
      inputCSS:
        "w-full text-center mt-3 px-4 py-2 border border-gray-300 rounded-md text-gray-800 focus:outline-none focus:border-body-1 focus:bg-body-1 focus:bg-opacity-5 uppercase",
      cislo_parcely: "",
      valuoData: null,
      openValuoJson: false,
      locale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
      strategiesAndDocs: {
        priceMap: null,
        titleDeed: null,
        proposalPropertyDeposit: null,
      },
      priceMap: {
        stripePriceId: IS_BETA || IS_LOCALHOST
          ? "price_1IqHHiI4HEbZGFYDM07WopNY"
          : "price_1LhUNDI4HEbZGFYDzkDnIk1B",
        price: 1000,
        selectedStrategy: 1,
        userPrice: null,
      },
      titleDeed: {
        stripePriceId: IS_BETA || IS_LOCALHOST
          ? "price_1IqHJgI4HEbZGFYD2XfSfCGn"
          : "price_1LhUPxI4HEbZGFYDEg7aOffe",
        price: 150,
        selectedStrategy: 2,
        files: [],
      },
      proposalPropertyDeposit: {
        stripePriceId: IS_BETA || IS_LOCALHOST
            ? "price_1IqHIsI4HEbZGFYDbTvrgK5P"
            : "price_1LBHT4I4HEbZGFYDs3FwKCZ9",
        price: 400,
        selectedStrategy: 3,
      },
      priceMapOrdered: false,
      titleDeedOrdered: false,
      propertyDepositOrdered: false,

      addPriceModalOpen: false,
      addOwnershipModalOpen: false,
      //paidServices
      arrayOfPaidServices: [],
      priceMapComparator: false,
      titleDeedComparator: false,
      propertyDepositComparator: false,
      //allDataFetched ?
      allDataFetched: false,

      //uploadManager
      openUploadManager: false,
      files: [],
      userImages: [],
      canContinueToUpload: false,
      changedPrice: "",
      changedPriceOnce: false,
      openErrorModal: false,

      //response is saved for all possible data to get title deed
      possibleCitties: [],

      // parcel data
      parcel_data: [],
      parcel_data_succeed: false,
      stavbaSoucastiParcely: false,

      // new cadastral enviroment
      cadastralBuilding: [],
      cadastralData: null,
      cadastralExecuted: false,
      cadastralSucceed: false,
      cadastralError: false,
      cadastralDataInDatabase: false,
      compositionCount: null,
      cadastralCount: null,
      generateToEmail: "Zaslat na email",
      currentComposition: null,

      // bought and used
      cadastralSendedToEmail: false,
      isPdfLv: "",
    };
  },

  methods: {
    getLvWithNewTab() {
      window.open(this.isPdfLv.pdfUrl, "_blank");
    },

    async getValuoForFree() {
      await axios({
        method: "get",
        url: `${process.env.VUE_APP_STRAPI_API_URL}/properties/valuo/${this.currentProperty.id}`,
        headers: {
          Authorization: `Bearer ${this.user.jwt}`,
        },
      }).then((result) => {
        this.valuoData = result.data;
        this.openValuoJson = !this.openValuoJson;
      });
      this.priceMapComparator = true;
    },

    updateValuoPrice(price) {
      this.valuoPriceClicked = true;
      this.price = price;
      if (this.price) {
        this.addPriceModalOpen = !this.addPriceModalOpen;
      }
    },
    async titleDeedChoose() {
      if (this.user.id) {
        const propertyData = JSON.stringify({
          id: this.currentProperty.id,
          email: this.currentProperty.user_id.email,
          name: this.currentProperty.name,
          propertyType: this.currentProperty.propertyType,
          propertyState: this.currentProperty.propertyState,
          propertyOwnership: this.currentProperty.propertyOwnership,
          propertyDisposition: this.currentProperty.propertyDisposition,
          propertyDescription: this.currentProperty.propertyDescription,
          propertyAdditional: this.currentProperty.propertyAdditional,
          address: this.currentProperty.address,
          city: this.currentProperty.city,
          latitude: this.currentProperty.latitude,
          longitude: this.currentProperty.longitude,
        });

        this.cadastralExecuted = true;
        this.cadastralBuilding = await getCadastralBuilding(encodeURIComponent(propertyData));
        if (this.cadastralBuilding.status === 200) {
          this.cadastralSendedToEmail = true;
          this.cadastralSucceed = true;
          this.cadastralData = await getCadastralData(this.currentProperty.id);
          this.cadastralDataInDatabase = true;
          setTimeout(() => {
            this.cadastralExecuted = false;
          }, 3000);
        } else {
          this.cadastralError = true;
          setTimeout(() => {
            this.cadastralExecuted = false;
          }, 3000);
          await this.getLV();
        }
      }
      return;
    },

    async update() {
      const data = await axios.get(
        `${process.env.VUE_APP_STRAPI_API_URL}/properties`,
        {
          headers: {
            Authorization: `Bearer ${this.user.jwt}`,
          },
        }
      );
      if (data.data.find((i) => i.id === this.currentProperty.id)) {
        const property = data.data.find(
          (i) => i.id === this.currentProperty.id
        );
        this.$store.commit("main/SET_CURRENT_PROPERTY", property);
        localStorage.removeItem("created");
      }
    },
    async uploadUserImagesHandler() {
      this.files = this.$refs.myFiles.files;
      this.canContinueToUpload = false;
      let formData = new FormData();
      //Alway multiple upload of these files
      Array.from(this.files).forEach((image) => {
        formData.append("files", image);
      });
      //Request to upload on DO provider
      await axios
        .post(`${process.env.VUE_APP_STRAPI_API_URL}/upload`, formData, {
          headers: {
            Authorization: `Bearer ${this.user.jwt}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.userImages = res.data.map((i) => i.url);
        })
        .catch((err) => {
          return err;
        });
      //Save url of images into database with user_id
      const images = this.userImages;
      await axios
        .post(
          `${process.env.VUE_APP_STRAPI_API_URL}/user-files`,
          {
            user_id: this.user.id,
            document: images,
          },
          {
            headers: {
              Authorization: `Bearer ${this.user.jwt}`,
            },
          }
        )
        .then((res) => {
          return res;
        })
        .catch((err) => {
          return err;
        });
      await axios
        .get(`${process.env.VUE_APP_STRAPI_API_URL}/user-files`, {
          headers: {
            Authorization: `Bearer ${this.user.jwt}`,
          },
        })
        .then((res) => {
          if (res.data.length) {
            this.canContinueToUpload = true;
          }
        })
        .catch((err) => {
          return err;
        });
    },
    openUploadManagerHandler() {
      this.openUploadManager = !this.openUploadManager;
    },
    async save() {
      try {
        this.$router.push("/priprava-nemovitosti");
      } catch (error) {
        this.$toast(this.$t("ErrorMessages.ErrorWhileSaving"), {
          className: ["et-alert"],
        });
      }
    },
    serialize(data) {
      if (data === null) {
        return `Cena na vyžádání`;
      } else
        return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ` Kč`;
    },
    orderPriceMap() {
      this.handleMapPrices(1000, null, true, this.priceMap);
    },
    removePriceMap() {
      this.handleMapPrices(1000, null, false, null);
    },
    async updatePriceMap(status) {
      if (status === 200) {
        this.addPriceModalOpen = !this.addPriceModalOpen;
        this.update();
        return status;
      } else {
        setTimeout(() => {
          this.changePriceErrorModal = true;
          setTimeout(() => {
            this.changePriceErrorModal = false;
          }, 3000);
        }, 1000);
      }
    },
    handleMapPrices(price, userPrice, ordered, priceMap) {
      this.priceMap.price = price;
      this.priceMap.userPrice = userPrice;
      this.priceMapOrdered = ordered;
      this.strategiesAndDocs.priceMap = priceMap;
    },
    closeModalPrice() {
      this.addPriceModalOpen = !this.addPriceModalOpen;
      this.removePriceMap();
    },
    closeModalError() {
      this.openErrorModal = !this.openErrorModal;
    },
    orderTitleDeed() {
      this.handleTitleDeed(true, this.titleDeed);
    },
    removeTitleDeed() {
      this.handleTitleDeed(false, null);
    },
    handleTitleDeed(ordered, titleDeed) {
      this.titleDeedOrdered = ordered;
      this.strategiesAndDocs.titleDeed = titleDeed;
    },
    updateOwnership(file) {
      this.titleDeed.files.push(file);
      this.handleTitleDeed(true, this.titleDeed);
    },
    closeModalOwnership() {
      this.addOwnershipModalOpen = !this.addOwnershipModalOpen;
    },
    async paidServiceHandler(receiptDescriptionArray) {
      if (receiptDescriptionArray.includes("1x Cenová mapa")) {
        await axios({
          method: "get",
          url: `${process.env.VUE_APP_STRAPI_API_URL}/properties/valuo/${this.currentProperty.id}`,
          headers: {
            Authorization: `Bearer ${this.user.jwt}`,
          },
        }).then((result) => {
          this.valuoData = result.data;
          this.openValuoJson = !this.openValuoJson;
        });
        this.priceMapComparator = true;
      } else this.priceMapComparator = false;
      if (receiptDescriptionArray.includes("1x List vlastnictví")) {
        this.titleDeedComparator = true;
      } else this.titleDeedComparator = false;
      if (receiptDescriptionArray.includes("1x Návrh na vklad nemovitosti")) {
        this.propertyDepositComparator = true;
      } else this.propertyDepositComparator = false;
      this.allDataFetched = true;
    },
    async getLV() {
      try {
        await axios({
          method: "get",
          url: `${process.env.VUE_APP_STRAPI_API_URL}/user-pdfs/${this.currentProperty.id}`,
          headers: {
            Authorization: `Bearer ${this.user.jwt}`,
          },
        }).then((data) => (this.isPdfLv = data.data));
      } catch (e) {
        return e;
      }
    },
  },
  watch: {
    $route() {
      this.isLoading = 0;
    },
  },
  async mounted() {
    // isPdfLv
    await this.getLV();

    // Valuo for free
    let valuoDataInDatabase;
    try {
      await axios({
        method: "get",
        url: `${process.env.VUE_APP_STRAPI_API_URL}/valuo-data/${this.currentProperty.id}`,
        headers: {
          Authorization: `Bearer ${this.user.jwt}`,
        },
      }).then(() => (valuoDataInDatabase = true));
    } catch (e) {
      valuoDataInDatabase = false;
      this.freeValuoDataShowButton = true;
    }
    try {
      valuoDataInDatabase
        ? await axios({
            method: "get",
            url: `${process.env.VUE_APP_STRAPI_API_URL}/properties/valuo/${this.currentProperty.id}`,
            headers: {
              Authorization: `Bearer ${this.user.jwt}`,
            },
          }).then((result) => {
            if (result.status === 200) {
              this.valuoData = result.data;
              this.openValuoJson = !this.openValuoJson;
              this.freeValuoDataShowButton = false;
              this.priceMapComparator = true;
            }
          })
        : null;
    } catch (e) {
      e;
    }
    // Valuo for free ^^
    const compositionList = await getCompositionList(this.currentProperty.id)
    this.currentComposition = compositionList[compositionList.length - 1]

    this.cadastralData = await getCadastralData(this.currentProperty.id);
    if (this.cadastralData) {
      if (this.cadastralData.data) {
        if (this.cadastralData.data.propertyType === this.currentComposition.propertyType && this.cadastralData.data.sended) {
          this.cadastralSendedToEmail = this.cadastralData.data.sended
        }
      }
    }
    if (this.cadastralData) {
      if (this.cadastralData.dataInDatabase) {
        this.cadastralDataInDatabase = true;
        if (this.cadastralData.data.data.documentCase === 3) {
          this.warningCase3 = true;
        }
        if (!this.cadastralData.data.data) {
          this.cadastralData = null;
        }
        if (window.localStorage.getItem("price")) {
          this.changedPriceOnce = true;
        }
      }
    }

    this.compositionCount = compositionList.length;

    await getCadastralList(this.currentProperty.id).then((res) => {
      this.cadastralCount = res.length;
    });
    // Stripe getData by currentPropertyId MODULE
    const receipts = await getStripe(this.currentProperty.id);
    if (receipts) {
      let receiptDescriptionArray = [];
      receipts.forEach((receipt) =>
        receiptDescriptionArray.push(receipt.description)
      );
      this.paidServiceHandler(receiptDescriptionArray);
      this.allDataFetched = true;
    }
    // ^^^
    // If propertyPrice was defined, immidiately fetch from database
    if (this.changedPrice === "") {
      const changedPrice = await axios.get(
        `${process.env.VUE_APP_STRAPI_API_URL}/properties/${this.currentProperty.id}`,
        {
          headers: {
            Authorization: `Bearer ${this.user.jwt}`,
          },
        }
      );
      return (this.changedPrice = changedPrice.data.propertyPrice);
    }
  },
};
</script>

<style scoped>
.btn-remove-price-map {
  position: relative;
  bottom: 35px;
  left: 40px;
  float: right;
}
</style>
